import React from 'react';
import PropTypes from 'prop-types';
import { Image, Text, Flex, Box } from 'rebass';
import { StaticQuery, graphql } from 'gatsby';
import styled from 'styled-components';
import Fade from 'react-reveal/Fade';
import Section from '../components/Section';
import { CardContainer, Card } from '../components/Card';
import SocialLink from '../components/SocialLink';
import Triangle from '../components/Triangle';

const LogosContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  padding: 3rem;
  & > div {
    flex: 1 0 25%;
    margin-top: 3rem;
  }
  & > div + div {
    margin-left: 3rem;
  }
`;

const Background = () => (
  <div>
    <Triangle
      color="backgroundDark"
      height={['25vh', '20vh']}
      width={['100vw', '100vw']}
      invertY
    />
  </div>
);

const MEDIA_QUERY_SMALL = '@media (max-width: 400px)';

const Technology = ({ logo, technologyUrl }) => (
  <Flex style={{ justifyContent: 'center' }}>
    {logo && (
      <a href={technologyUrl}>
        <Image src={logo.image.src} alt={logo.title} />
      </a>
    )}
  </Flex>
);

Technology.propTypes = {
  name: PropTypes.string.isRequired,
  technologyUrl: PropTypes.string.isRequired,
  logo: PropTypes.shape({
    image: PropTypes.shape({
      src: PropTypes.string,
    }),
    title: PropTypes.string,
  }),
};

const Technologies = () => (
  <Section.Container
    id="technologies"
    Background={Background}
    backgroundColor="#f2f2f2"
  >
    <Section.Header name="Technologies" />
    <StaticQuery
      query={graphql`
        query TechnologiesQuery {
          contentfulAbout {
            technologies {
              id
              name
              technologyUrl
              logo {
                title
                image: resize(height: 100, quality: 100) {
                  src
                }
              }
            }
          }
        }
      `}
      render={({ contentfulAbout }) => (
        <LogosContainer>
          {contentfulAbout.technologies.map((p, i) => (
            <Fade bottom delay={i * 200} key={p.id}>
              <Technology {...p} />
            </Fade>
          ))}
        </LogosContainer>
      )}
    />
  </Section.Container>
);

export default Technologies;
